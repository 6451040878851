<template>
  <div>
    <h1 class="text-h4">Управление продажами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form ref="form" v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Обработка заказа {{ order.code }}</v-card-title>
        <v-card-text>
          <v-combobox
            v-model="form.data.manager"
            label="Менеджер"
            persistent-hint
            clearable
            :items="managerOptions"
            :loading="busy"
          >
          </v-combobox>
          <v-combobox
            v-model="form.data.worker"
            label="Исполнитель / Флорист"
            persistent-hint
            clearable
            :items="workerOptions"
            :loading="busy"
          >
          </v-combobox>
          <v-alert
            v-if="item.deliveryType==='Delivery'"
            prominent
            outlined
            dense
            text
            type="info"
            border="left"
          >
            Обращаем внимание, по заказу требуется доставка на {{ item.deliveryDate }} в промежуток времени: {{ item.deliveryTimeSlot | timeSlot }}
          </v-alert>
          <v-alert
            v-else
            prominent
            outlined
            dense
            text
            type="success"
            border="left"
          >
            Заказ не требует доставки
          </v-alert>
          <v-row no-gutters dense>
            <v-col cols="12" sm="6">
              <drop-down-date-picker v-model="form.data.shippingDate" label="Запланировать доставку" />
            </v-col>
            <v-col cols="12" sm="6" class="pl-sm-2">
              <drop-down-time-picker v-model="form.data.shippingTime" label="Время отправки" />
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'order.view', params: { id }}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import DropDownDatePicker from '@/components/ui/DropDownDatePicker.vue'
import OrderManageForm from '@/model/order/OrderManageForm'
import DropDownTimePicker from '@/components/ui/DropDownTimePicker.vue'

export default {
  ...dashboardPageCommonAttributes,
  name: 'OrderManagePage',
  components: { DropDownTimePicker, DropDownDatePicker },
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      order: {
        code: null
      },
      form: {
        valid: true,
        data: {
          shippingDate: null,
          shippingTime: null,
          manager: null,
          worker: null
        },
        rule: {
          shippingDate: []
        }
      },
      managerOptions: [],
      workerOptions: []
    }
  },
  computed: {
    ...mapState('order_manage', ['item', 'users', 'busy'])
  },
  created() {
    this.fetchData({ id: this.id })
      .then((_) => {
        this.order.code = this.item.code

        this.workerOptions = this.users
          .filter((u) => ['Admin','Manager','Florist'].includes(u.role))
          .map((u) => { return {
            text: `${u.fullName} <${u.email}>`, value: u.id
          }} )
        this.managerOptions = this.users
          .filter((u) => ['Admin','Manager'].includes(u.role))
          .map((u) => { return {
            text: `${u.fullName} <${u.email}>`, value: u.id
          }} )

        if (this.item.shippingAt) {
          this.form.data.shippingDate = this.item.shippingAt.substring(0,10)
          this.form.data.shippingTime = this.item.shippingAt.substring(11)
        }

        // this.form.data.shippingDate = this.item.shippingAt

        const managerId = this.item.manager?.id

        if (managerId) {
          const manager = this.managerOptions.find((u) => u.value === managerId)

          if (manager) {
            this.form.data.manager = manager
          }
        }

        const workerId = this.item.worker?.id

        if (workerId) {
          const worker = this.workerOptions.find((u) => u.value === workerId)

          if (worker) {
            this.form.data.worker = worker
          }
        }
      })
  },
  methods: {
    ...mapActions('order_manage', ['fetchData', 'updateItem']),
    submitForm() {
      const { data } = this.form

      const form = OrderManageForm.create({
        // shippingAt: data.shippingAt,
        managerId: data.manager?.value ?? null,
        workerId: data.worker?.value ?? null
      })

      if (data.shippingDate && data.shippingTime) {
        const nowIso = (new Date()).toISOString()
        const date = data.shippingDate ?? nowIso.substring(0,10)
        const time = data.shippingTime ?? '00:00:00'

        form.shippingAt = `${date}T${time}`
      }
      else {
        form.shippingAt = null
      }

      this.updateItem({ form }).then(() => {
        ToastService.success('Данные сохранены')

        this.$router.go(-1)
      })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    }
  }
}
</script>
